//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    item: { type: Object, required: true },
  },
  methods: {
    isAbsolute(url) {
      return /^https?:\/\//.test(url)
    },
  },
}
